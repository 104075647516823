<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="page-content">
				<div class="tabContent">
					<div class="title-bar">招考报名 > {{info.name}}</div>
					<div class="bm-wapper">
						<div class="bm-bar">
							<step-bar :active="1"></step-bar>
						</div>
						<div class="bm-content">
							<el-form :model="form" label-width="130px" size="small">
								<div class="form-title">报名表</div>
								<div class="form-title-bar">基本信息</div>
								<div class="form-item-wapper">
									<div class="flex">
										<div class="col2 flex1">
											<el-form-item label="姓名">
												<el-input placeholder="请输入" v-model="form.name"></el-input>
											</el-form-item>
											<el-form-item label="性别">
												<el-select v-model="form.sex">
													<el-option label="男" :value="1"></el-option>
													<el-option label="女" :value="0"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="出生年月">
												<el-date-picker value-format="yyyy-MM-dd" v-model="form.birthday">
												</el-date-picker>
											</el-form-item>
											
											<el-form-item label="民族">
												<el-select v-model="form.nation">
													<el-option v-for="item in optionmz" :key="item.id"
														:label="item.name" :value="item.id"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="籍贯">
												<el-cascader size="large" :options="regionData" v-model="houseHold"
													@change="handleChange">
												</el-cascader>
											</el-form-item>
											<el-form-item label="入党时间">
												<el-date-picker value-format="yyyy-MM-dd" v-model="form.partyTime">
												</el-date-picker>
											</el-form-item>
											<el-form-item label="参加工作时间">
												<el-date-picker value-format="yyyy-MM-dd" v-model="form.workTime">
												</el-date-picker>
											</el-form-item>
											<el-form-item label="熟悉专业有何专长">
												<el-input placeholder="请输入" v-model="form.specialty"></el-input>
											</el-form-item>
											<el-form-item label="身高">
												<el-input placeholder="请输入" v-model="form.height"></el-input>
												<i class="xz">cm</i>
											</el-form-item>
											<el-form-item label="体重">
												<el-input placeholder="请输入" v-model="form.weight"></el-input>
												<i class="xz">kg</i>
											</el-form-item>
											
											<el-form-item label="证件号">
												<el-input placeholder="请输入" v-model="form.idcard"></el-input>
											</el-form-item>
											<el-form-item label="联系方式">
												<el-input placeholder="请输入" v-model="form.phone"></el-input>
											</el-form-item>
											<el-form-item label="居住地" style="width: 100%;">
												<el-input placeholder="请输入" v-model="form.homeAddress"></el-input>
											</el-form-item>
										</div>
										<div class="btn-upload-pic-wapper">
											<el-upload class="avatar-uploader"
												:action="baseurl + '/Admin/Upload/uploadimage'"
												accept="image/png, image/jpeg" :show-file-list="false"
												:on-success="uploadLogoSuccess" :on-error="uploadimageerror">
												<div class="btn-upload-pic">
													<div v-if="form.avatar">
														<img :src="defimgurl+form.avatar" fit="contain" width="100"
															height="120"></img>
													</div>
													<div v-else>
														<div class="btn-plus">
															<i class="el-icon-plus"></i>
														</div>
														<div>上传照片</div>
													</div>
												</div>
											</el-upload>
											<div class="btn-upload-pic-text">一寸照格式</div>
										</div>
									</div>

								</div>
								<div class="form-title-bar">教育经历</div>
								<div class="form-item-wapper">
									<div class="flex">
										<div class="col2 flex1">
											<el-form-item label="全日制教育">
												<el-select v-model="form.education">
													<el-option v-for="item in educationlist" :key="item.cCode"
														:label="item.cName" :value="item.cCode"></el-option>
												</el-select>
											</el-form-item>
											<el-form-item label="毕业院校">
												<el-input placeholder="请输入" v-model="form.school"></el-input>
											</el-form-item>
											<el-form-item label="专业">
												<el-input placeholder="请输入" v-model="form.professional"></el-input>
											</el-form-item>
											
											<el-form-item label="在职教育">
												<el-input placeholder="请输入" v-model="form.education1"></el-input>
											</el-form-item>
											<el-form-item label="毕业院校">
												<el-input placeholder="请输入" v-model="form.school1"></el-input>
											</el-form-item>
											<el-form-item label="专业">
												<el-input placeholder="请输入" v-model="form.professional1"></el-input>
											</el-form-item>
										</div>
										<div class="btn-upload-pic-wapper">
										</div>
									</div>
								</div>
								<div class="form-title-bar">主要简历</div>
								<div class="form-item-wapper">
									<el-form-item label-width="0">
										<el-input placeholder="请输入简历" type="textarea" :rows="5" v-model="form.resume">
										</el-input>
									</el-form-item>
								</div>
								<div class="form-title-bar">家庭主要成员及重要社会关系</div>
								<div>
									<el-table :data="memberOfFamily" border>
										<el-table-column label="称谓" prop="appellation">
										</el-table-column>
										<el-table-column label="姓名" prop="name"></el-table-column>
										<el-table-column label="出生年月" prop="dateOfBirth"></el-table-column>
										<el-table-column label="政治面貌" prop="political"></el-table-column>
										<el-table-column label="工作单位及职务">
											<template slot-scope="scope">
												<div>
													{{scope.row.workUnit}} {{scope.row.post}}
												</div>
											</template>
										</el-table-column>
										<el-table-column label="操作">
											<template slot-scope="scope">
												<div>
													<el-button size="mini" @click="updateMember(scope.$index)">编辑
													</el-button>
													<el-button size="mini" @click="removeMember(scope.$index)">删除
													</el-button>
												</div>
											</template>
										</el-table-column>
									</el-table>
									<div class="btn-group-addmember">
										<el-button size="mini" icon="el-icon-plus" @click="openAddMember()">添加成员
										</el-button>
									</div>
								</div>
								<div class="form-title-bar flex">
									<div class="flex1">选择岗位</div>
									<div class="padding-right">
										<el-button type="primary" icon="el-icon-plus" size="mini"
											@click="openItem=true">选择岗位</el-button>
									</div>
								</div>
								<div>
									<el-table :data="iteminfo" border>
										<el-table-column label="序号" type="index" width="60" align="center">
										</el-table-column>
										<el-table-column label="单位" prop="examCompanyName" width="130">
										</el-table-column>
										<el-table-column label="岗位" prop="name" width="120"></el-table-column>
										<el-table-column label="要求" prop="memo"></el-table-column>
										<el-table-column label="人数" prop="num" width="60" align="center">
										</el-table-column>
									</el-table>
								</div>
							</el-form>

							<div class="foot-btn-group">
								<el-button type="primary" @click="gonext()">下一步</el-button>
								<el-button type="info" @click="goreset()">重置</el-button>
								<el-button @click="goback()">取消</el-button>
							</div>
							<div class="remark">
								<div class="remark-title"> 说明：</div>
								<div>
									1.个人简历包括学习简历和工作简历，学习简历要从高中开始填写，工作简历要填写清楚工作变化的时间；简历填写时间要到月，籍贯填写到县（市、区）、出生地填写到县（市、区）、镇。
								</div>
								<div>
									2.家庭成员和社会关系一般需填写配偶、子女、父母、岳父母（公婆）等，亲属中在行政企事业单位或经商办企业、旅居海外的必须填。
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="添加成员" :visible.sync="openMember" :close-on-click-modal="false">
			<el-form :model="formMember" label-width="100px">
				<el-form-item label="称谓">
					<el-input v-model="formMember.appellation" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="formMember.name" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="出生年月">
					<el-date-picker v-model="formMember.dateOfBirth" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="政治面貌">
					<el-select v-model="formMember.political">
						<el-option :label="item" :key="item" :value="item" v-for="(item,index) in enums.political">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="工作单位">
					<el-input v-model="formMember.workUnit" placeholder="请输入"> </el-input>
				</el-form-item>
				<el-form-item label="职务">
					<el-input v-model="formMember.post" placeholder="请输入"> </el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="openMember = false">取 消</el-button>
				<el-button type="primary" @click="addformMember()">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="选择岗位" :visible.sync="openItem" :close-on-click-modal="false">
			<div v-if="itemlist.length > 0">
				<template>
					<el-table :data="itemlist" stripe style="width: 100%" border header-row-class-name="headerrowclass"
						row-class-name="rowclass">
						<el-table-column label="序号" type="index" width="60" align="center">
						</el-table-column>
						<el-table-column prop="examCompanyName" label="单位" width="130">
						</el-table-column>
						<el-table-column prop="name" label="岗位" width="120">
						</el-table-column>
						<el-table-column prop="memo" label="要求">
						</el-table-column>
						<el-table-column prop="num" label="人数" width="60" align="center">
						</el-table-column>
						<el-table-column label="操作" width="80" align="center">
							<template slot-scope="scope">
								<a @click="examitem(scope.row)" style="color: #276bf2;margin-right: 10px;">选择</a>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
			<el-empty v-if="itemlist.length < 1" :image-size="200"></el-empty>
			<div style="text-align: center; margin-top: 20px">
				<el-pagination hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="selfrom.pageNumber" :page-sizes="[20, 50, 100, 150, 200]"
					:page-size="selfrom.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		Getposilist,
		GetNation,
		GetExamModel,
		GetExamItemList,
		GetExamByPid,
		GetExamAdd
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import StepBar from './stepbar.vue';
	import enums from '@/enums/index';
	import global from "@/assets/js/globalconfig";
	import {
		regionData
	} from "element-china-area-data";
	export default {
		components: {
			StepBar
		},
		data() {
			return {
				id: 0,
				info: {},
				form: {
					avatar: ""
				},
				houseHold: [],
				activeName: 'tab1',
				memberOfFamily: [],
				formMember: {},
				openMember: false,
				openItem: false,
				enums,
				operType: 'add',
				educationlist: [],
				regionData,
				optionmz: [],
				selfrom: {
					searchExamId: 0,
					pageNumber: 1,
					pageSize: 5,
					searchKey: "",
				},
				total: 0,
				itemlist: [],
				iteminfo: [],
				baseurl: global.baseurl,
				defimgurl: global.baseimgurl,
			};
		},
		created() {
			this.id = this.$route.query.id;
			if (!isnull(this.id)) {
				this.Getinfo(this.id);
				this.Getposilists(1, "学历");
				this.GetNation();
				this.GetExamItemList();
				this.GetExamByPid()
			} else {
				this.$router.push("/zhaokaobm")
			}
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.usertype = this.userinfo.userType;
			}
		},
		methods: {
			Getinfo(id) {
				GetExamModel({
					id: id
				}).then((res) => {
					if (res.success) {
						this.info = res.data;
					}
				});
			},
			handleChange(value) {
				this.form.houseHold = value[0] + "," + value[1] + "," + value[2];
			},
			GetExamByPid() {
				GetExamByPid({
					uid: 0
				}).then((res) => {
					if (res.success) {
						this.form = res.data
						this.form.id = 0;
						this.form.examId = 0;
						this.form.examCompanyId = 0;
						this.form.examCompanyItemId = 0;
						if (!isnull(this.form.memberOfFamily))
							this.memberOfFamily = JSON.parse(this.form.memberOfFamily)

						var hh = this.form.houseHold.split(',');
						if (hh.length > 2)
							this.houseHold = [hh[0], hh[1], hh[2]];
						console.log(this.form)
					}
				})

			},
			examitem(item) {
				this.iteminfo = []
				this.iteminfo.push(item);
				this.form.examId = item.examId;
				this.form.examCompanyId = item.examCompanyId;
				this.form.examCompanyItemId = item.id;
				this.openItem = false;
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.GetExamItemList();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.GetExamItemList();
			},
			GetExamItemList() {
				this.selfrom.searchExamId = this.id;
				GetExamItemList(this.selfrom).then((res) => {
					if (res.success) {
						this.itemlist = res.data.rows;
					}
				});
			},
			//上传logo成功
			uploadLogoSuccess(res, file) {
				this.$message.success("上传成功!");
				this.form.avatar = res.data.obj.files;
				console.log(this.form)
			},
			uploadimageerror(res, file) {
				this.$message.error("上传失败!");
			},
			GetNation() {
				GetNation({}).then((res) => {
					this.optionmz = res.data;
				})
			},
			Getposilists(type, datas) {
				var _this = this;
				Getposilist({
					types: datas
				}).then((res) => {
					if (res.success) {
						switch (type) {
							case 1: //学历
								_this.educationlist = res.data.rows;
								break;
							case 2: //行业
								_this.industrylist = res.data.rows;
								break;
							default:
								break;
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//获取学历
			geteducationlist(data) {
				if (isnull(data)) {
					return "";
				}
				for (let i = 0; i < this.educationlist.length; i++) {
					if (this.educationlist[i].cCode == data)
						return this.educationlist[i].cName
				}
			},
			updateMember(index) {
				this.operMemberIndex = index;
				this.formMember = {
					...this.memberOfFamily[index]
				};
				this.openMember = true;
			},
			removeMember(index) {
				this.memberOfFamily.splice(index, 1);
			},
			gonext(e) {
				if (isnull(this.form.name)) {
					this.$message.error("请填写姓名");
					return false;
				}
				if (isnull(this.form.idcard)) {
					this.$message.error("请填写证件号");
					return false;
				}
				if (isnull(this.form.examCompanyItemId)) {
					this.$message.error("请选择一个岗位");
					return false;
				}
				this.from.personId = this.userinfo.peR_ID;
				this.form.memberOfFamily = JSON.stringify(this.memberOfFamily);
				this.$modal.confirm("请认真核对填报信息是否正确，保存后不可更改")
					.then(() => {
						GetExamAdd(this.form).then((res) => {
							if (res.success)
								this.$router.push("bmComplate?id=" + res.data)
							else
								this.$message.error(res.msg);
						})
					})
			},
			goreset(e) {
				this.form = {};
				this.memberOfFamily = [];
				this.houseHold = [];
			},
			goback() {
				this.$router.push("zhaokaoInfo?id=" + this.id)
			},
			addformMember() {
				if (this.operMemberIndex == null) {
					this.memberOfFamily.push({
						...this.formMember
					});
				} else {
					let memberOfFamily = JSON.parse(JSON.stringify(this.memberOfFamily))
					memberOfFamily[this.operMemberIndex] = {
						...this.formMember
					};
					this.memberOfFamily = memberOfFamily;
				}
				this.openMember = false;
			},
			openAddMember() {
				this.operMemberIndex = null;
				this.formMember = {};
				this.openMember = true;
			}
		},
	};
</script>
<style lang="scss" scoped>
	.title-bar {
		padding: 8px 20px;
		background-color: rgba(25, 144, 255, 0.1);
		font-size: 13px;
		color: #101010;
		font-weight: bold;
	}

	.bm-wapper {
		padding: 20px 0;
		display: flex;
	}

	.bm-bar {
		width: 180px;
	}

	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		::v-deep .el-tabs__header {
			margin: 0;
		}

		::v-deep .el-tabs__item {
			padding: 0 80px;
		}
	}

	.tabContent {
		background: #FFFFFF;
		padding: 20px;

	}

	.bm-content {
		padding: 0 16px;
		flex: 1;
	}

	.form-title {
		font-size: 28px;
		text-align: center;
		padding: 10px 0;
	}

	.form-title-bar {
		height: 40px;
		line-height: 40px;
		background: #f8f8f8;
		border-left: 4px solid #5bb0ff;
		padding-left: 25px;
		color: #101010;
		font-size: 15px;
	}

	.flex {
		display: flex;
	}

	.flex1 {
		flex: 1;
	}

	.padding-right {
		padding-right: 10px;
	}

	.form-item-wapper {
		padding-top: 10px;
	}

	.col2 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		::v-deep .el-form-item {
			width: 33%;
		}

		::v-deep .el-form-item__label {
			font-size: 12px;
			color: #101010;
		}
		::v-deep .el-form-item__content{
			position: relative;
			i.xz{position: absolute;}
		}
	}

	.btn-upload-pic-wapper {
		padding-top: 20px;
		width: 184px;

	}

	.avatar-uploader {
		margin-left: auto;
		width: 104px;
	}

	.btn-upload-pic {
		width: 104px;
		height: 125px;
		background: #f8f8f8;
		color: rgba(0, 0, 0, 0.65);
		border: 1px solid dashed;
		text-align: center;
		font-size: 14px;
		cursor: pointer;
	}

	.btn-plus {
		padding: 25px 0;
		font-size: 24px;
	}

	.btn-upload-pic-text {
		color: rgba(0, 0, 0, 0.45);
		text-align: center;
		margin-left: auto;
		width: 104px;
		font-size: 14px;
		padding-top: 12px;
	}

	::v-deep .el-select {
		display: unset;
	}

	::v-deep .el-date-editor {
		width: 100%;
	}

	::v-deep .el-cascader {
		display: unset;
	}

	::v-deep .el-table th {
		background-color: #efefef;
	}


	.foot-btn-group {
		padding-top: 80px;
		padding-bottom: 20px;
	}

	.remark {
		font-size: 14px;
	}

	.remark-title {
		color: rgba(253, 54, 54, 1);
	}

	.btn-group-addmember {
		padding: 10px 0;
		text-align: center;
	}
</style>
